import * as dsp from '../constant/dsp';

const initialState = {
  userInfo: [],
};

export default function adset(state = initialState, action) {
  switch (action.type) {
    case dsp.dspLogin.request: {
      return {
        ...state,
        userInfo: [],
      };
    }
    case dsp.dspLogin.success: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case dsp.dspLogin.failure: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
