import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function LoginProvider(props) {
  const { children } = props;
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('liveStreamToken');
    const authPathnames = ['/login'];

    const tokenExpTime = () => {
      const base64Payload = token.split('.')[1];
      const payload = Buffer.from(base64Payload, 'base64');
      const currentTimestamp = String(Date.now()).slice(0, 10);
      const tokenExpTime = JSON.parse(payload.toString()).exp;
      return Number(currentTimestamp) > tokenExpTime;
    };

    if (!token) {
      navigate('/login');
    } else if (tokenExpTime()) {
      navigate('/login');
    } else if (authPathnames.includes(window.location.pathname)) {
      navigate('/');
    }
  }, []);

  return children;
}

LoginProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default LoginProvider;
