import * as streamer from '../constant/streamer'

const initialState = {
  liveAdset: [],
}

export default function adset(state = initialState, action) {
  switch (action.type) {
    case streamer.getLiveStreamerAdset.request: {
      return {
        ...state,
        liveAdset: [],
      }
    }
    case streamer.getLiveStreamerAdset.success: {
      return {
        ...state,
        liveAdset: action.payload,
      }
    }
    case streamer.getLiveStreamerAdset.failure: {
      return {
        ...state,
        error: action.payload,
      }
    }    
    default:
      return state
  }
}