const JSSCRIPT = [
  'https://cdn.aralego.net/rich_media/sa/live_stream_300_250_v3_nocomment_template.js',
  'https://cdn.aralego.net/rich_media/sa/live_stream_336_280_v3_nocomment_template.js',
  'https://cdn.aralego.net/rich_media/sa/live_stream_970_250_v3_nocomment_template.js',
];
const ClOUDFLAREJSSCRIPT = [
  'https://cdn.aralego.net/rich_media/sa/live_stream_300_250_v1_cloudflare_template.js',
  'https://cdn.aralego.net/rich_media/sa/live_stream_336_280_v1_cloudflare_template.js',
  'https://cdn.aralego.net/rich_media/sa/live_stream_970_250_v1_cloudflare_template.js',
];
const DEFAULT_IMAGE = [
  'https://cdn.aralego.net/resources/showmore/300x250.png',
  'https://cdn.aralego.net/resources/showmore/336x280.png',
  'https://cdn.aralego.net/resources/showmore/970x250.png',
];

const divSize = [
  { width: 300, height: 250 },
  { width: 336, height: 280 },
  { width: 970, height: 250 },
];

function Creative(props) {
  const { videoUrl, videoName, size, image, landingPage, backupImage } = props;
  const jsSrc = videoUrl.includes('facebook')
    ? JSSCRIPT[size]
    : ClOUDFLAREJSSCRIPT[size];
  const imgUrl = image || DEFAULT_IMAGE[size];
  const htmlContent = `<html style="height:100%"><body style="width: 100%;height: 100%;padding:0;margin:0;"><div style="width: 100%;height:100%;display: table;"><div style="height:100%;display: table-cell;text-align: center;vertical-align: middle;"><ins id="ucad_template"></ins><script>    \
        var a = { 
        videoUrl: "${videoUrl}",\
        name: "${videoName}",\
        imgUrl: "${imgUrl}", // background image, if empty use default image in js \n
        landingPage: "${landingPage}",\
        backupImgUrl: "${backupImage}"\
        };\
    </script>\
	<script src="${jsSrc}"></script></div></div></body></html>`;

  return (
    /*
		<div
		   dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
*/
    <iframe
      title={videoUrl}
      width={divSize[size].width}
      height={divSize[size].height}
      srcDoc={htmlContent}
      referrerPolicy="same-origin"
    ></iframe>
  );
}

export default Creative;
