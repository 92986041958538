import * as liveVideoStreamStatisticsData from '../constant/statisticData';
const initialState = {
  statisticData: [],
};

export default function statisticData(state = initialState, action) {
  switch (action.type) {
    case liveVideoStreamStatisticsData.getLiveVideoStreamStatisticsData
      .request: {
      return {
        ...state,
        statisticData: [],
      };
    }
    case liveVideoStreamStatisticsData.getLiveVideoStreamStatisticsData
      .success: {
      return {
        ...state,
        statisticData: action.payload,
      };
    }
    case liveVideoStreamStatisticsData.getLiveVideoStreamStatisticsData
      .failure: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
