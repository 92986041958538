import { alertStatus } from '../constant/alert'

export const showAlert = (text, { type, duration, horizontal, vertical } = {}) => ({
  type: alertStatus.SHOW_ALERT,
  payload: { text, type, duration, horizontal, vertical }
})

export const hideAlert = () => ({
  type: alertStatus.HIDE_ALERT
})
