import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from 'pages/Login';
import LivestreamInfo from 'pages/LivestreamInfo';

const App = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route path="/" element={<LivestreamInfo />} />
    </Routes>
  );
};

export default App;
