import { combineReducers } from 'redux';

import adset from './adset';
import alert from './alert';
import dsp from './dsp';
import statisticData from './statisticData';

export default function createRootReducer() {
  const appReducer = combineReducers({
    adset,
    alert,
    dsp,
    statisticData,
  });

  const rootReducer = (state, action) => {
    return appReducer(state, action);
  };
  return rootReducer;
}
