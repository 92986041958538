import  * as adset from '../constant/streamer'
import { showAlert } from './alert'
import api from '../utils/api'
import camelcaseKeys from 'camelcase-keys'

export const getLiveAdset = (payload) => (dispatch) => {
  dispatch({ type: adset.getLiveStreamerAdset.request, payload })
  api
    .get(`/streaming/live_adsets`)
    .then((res) => {    
let mock = {
  "code": 200,
  "msg": "Ok",
  "data": [{
    "adsets": [{
      "name": "test2 live viewer",
      "adsetId": "adset-vq4xm5bQoBCqlJ6rzzJg",
      "start": "2021-12-06T09:41:00Z",
      "end": "2022-07-09T23:11:00Z",
      "KPI": "Live viewer",
      "CPM_limit": -1,
      "pacing_type": "ASAP",
      "origin_total_budget": 99999,
      "total_budget_usage": 0.06818712810900004,
      "total_impression_usage": 16,
      "expected_viewer": 10000
    }, {
      "name": "CI TestUserPage",
      "adset_id": "adset-AeR0RAj9zVHR1BPq702P",
      "start": "2021-12-20T00:00:00Z",
      "end": "2122-01-24T00:00:00Z",
      "KPI": "Max Impression",
      "CPM_limit": -1,
      "pacing_type": "EVEN",
      "origin_total_budget": -1,
      "total_budget_usage": 0,
      "total_impression_usage": 0,
      "expected_viewer": 0
    }],
    "advertiser": {
      "name": "streamer1",
      "viewers": 100
    },
    "stream": {
      "video_url": "https://www.facebook.com/niusbaby/videos/793142971377719",
      "landing_page": "https://www.facebook.com/niusbaby/videos/793142971377719?openExternalBrowser=1",
      "backup_image": "https://cdn.aralego.net/dsp/821a326d5b62ea13ae5991c9ec91f9a6967a9a1f64931e1ca6b81913a9ca0122.jpg",
      "images": [{
        "width": 970,
        "height": 250,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }, {
        "width": 336,
        "height": 280,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }]
    }
  },{
    "adsets": [{
      "name": "test2 live viewer",
      "adsetId": "adset-vq4xm5bQoBCqlJ6rzzJg",
      "start": "2021-12-06T09:41:00Z",
      "end": "2022-07-09T23:11:00Z",
      "KPI": "Live viewer",
      "CPM_limit": -1,
      "pacing_type": "ASAP",
      "origin_total_budget": 99999,
      "total_budget_usage": 0.06818712810900004,
      "total_impression_usage": 16,
      "expected_viewer": 10000
    }, {
      "name": "CI TestUserPage",
      "adset_id": "adset-AeR0RAj9zVHR1BPq702P",
      "start": "2021-12-20T00:00:00Z",
      "end": "2122-01-24T00:00:00Z",
      "KPI": "Max Impression",
      "CPM_limit": -1,
      "pacing_type": "EVEN",
      "origin_total_budget": -1,
      "total_budget_usage": 0,
      "total_impression_usage": 0,
      "expected_viewer": 0
    }],
    "advertiser": {
      "name": "streamer1",
      "viewers": 100
    },
    "stream": {
      "video_url": "https://www.facebook.com/niusbaby/videos/793142971377719",
      "landing_page": "https://www.facebook.com/niusbaby/videos/793142971377719?openExternalBrowser=1",
      "backup_image": "https://cdn.aralego.net/dsp/821a326d5b62ea13ae5991c9ec91f9a6967a9a1f64931e1ca6b81913a9ca0122.jpg",
      "images": [{
        "width": 970,
        "height": 250,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }, {
        "width": 336,
        "height": 280,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }]
    }
  },
{
    "adsets": [{
      "name": "test2 live viewer",
      "adsetId": "adset-vq4xm5bQoBCqlJ6rzzJg",
      "start": "2021-12-06T09:41:00Z",
      "end": "2022-07-09T23:11:00Z",
      "KPI": "Live viewer",
      "CPM_limit": -1,
      "pacing_type": "ASAP",
      "origin_total_budget": 99999,
      "total_budget_usage": 0.06818712810900004,
      "total_impression_usage": 16,
      "expected_viewer": 10000
    }, {
      "name": "CI TestUserPage",
      "adset_id": "adset-AeR0RAj9zVHR1BPq702P",
      "start": "2021-12-20T00:00:00Z",
      "end": "2122-01-24T00:00:00Z",
      "KPI": "Max Impression",
      "CPM_limit": -1,
      "pacing_type": "EVEN",
      "origin_total_budget": -1,
      "total_budget_usage": 0,
      "total_impression_usage": 0,
      "expected_viewer": 0
    }],
    "advertiser": {
      "name": "streamer1",
      "viewers": 100
    },
    "stream": {
      "video_url": "https://www.facebook.com/niusbaby/videos/793142971377719",
      "landing_page": "https://www.facebook.com/niusbaby/videos/793142971377719?openExternalBrowser=1",
      "backup_image": "https://cdn.aralego.net/dsp/821a326d5b62ea13ae5991c9ec91f9a6967a9a1f64931e1ca6b81913a9ca0122.jpg",
      "images": [{
        "width": 970,
        "height": 250,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }, {
        "width": 336,
        "height": 280,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }]
    }
  },
{
    "adsets": [{
      "name": "test2 live viewer",
      "adsetId": "adset-vq4xm5bQoBCqlJ6rzzJg",
      "start": "2021-12-06T09:41:00Z",
      "end": "2022-07-09T23:11:00Z",
      "KPI": "Live viewer",
      "CPM_limit": -1,
      "pacing_type": "ASAP",
      "origin_total_budget": 99999,
      "total_budget_usage": 0.06818712810900004,
      "total_impression_usage": 16,
      "expected_viewer": 10000
    }, {
      "name": "CI TestUserPage",
      "adset_id": "adset-AeR0RAj9zVHR1BPq702P",
      "start": "2021-12-20T00:00:00Z",
      "end": "2122-01-24T00:00:00Z",
      "KPI": "Max Impression",
      "CPM_limit": -1,
      "pacing_type": "EVEN",
      "origin_total_budget": -1,
      "total_budget_usage": 0,
      "total_impression_usage": 0,
      "expected_viewer": 0
    }],
    "advertiser": {
      "name": "streamer1",
      "viewers": 100
    },
    "stream": {
      "video_url": "https://www.facebook.com/niusbaby/videos/793142971377719",
      "landing_page": "https://www.facebook.com/niusbaby/videos/793142971377719?openExternalBrowser=1",
      "backup_image": "https://cdn.aralego.net/dsp/821a326d5b62ea13ae5991c9ec91f9a6967a9a1f64931e1ca6b81913a9ca0122.jpg",
      "images": [{
        "width": 970,
        "height": 250,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }, {
        "width": 336,
        "height": 280,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }]
    }
  },
{
    "adsets": [{
      "name": "test2 live viewer",
      "adsetId": "adset-vq4xm5bQoBCqlJ6rzzJg",
      "start": "2021-12-06T09:41:00Z",
      "end": "2022-07-09T23:11:00Z",
      "KPI": "Live viewer",
      "CPM_limit": -1,
      "pacing_type": "ASAP",
      "origin_total_budget": 99999,
      "total_budget_usage": 0.06818712810900004,
      "total_impression_usage": 16,
      "expected_viewer": 10000
    }, {
      "name": "CI TestUserPage",
      "adset_id": "adset-AeR0RAj9zVHR1BPq702P",
      "start": "2021-12-20T00:00:00Z",
      "end": "2122-01-24T00:00:00Z",
      "KPI": "Max Impression",
      "CPM_limit": -1,
      "pacing_type": "EVEN",
      "origin_total_budget": -1,
      "total_budget_usage": 0,
      "total_impression_usage": 0,
      "expected_viewer": 0
    }],
    "advertiser": {
      "name": "streamer1",
      "viewers": 100
    },
    "stream": {
      "video_url": "https://www.facebook.com/niusbaby/videos/793142971377719",
      "landing_page": "https://www.facebook.com/niusbaby/videos/793142971377719?openExternalBrowser=1",
      "backup_image": "https://cdn.aralego.net/dsp/821a326d5b62ea13ae5991c9ec91f9a6967a9a1f64931e1ca6b81913a9ca0122.jpg",
      "images": [{
        "width": 970,
        "height": 250,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }, {
        "width": 336,
        "height": 280,
        "url": "https://www.facebook.com/niusbaby/videos/793142971377719"
      }]
    }
  }

  ]
}
  //  res.data = camelcaseKeys(mock, { deep: true });

      if (res?.data?.code === 200) {
        dispatch({
          type: adset.getLiveStreamerAdset.success,
          payload: res.data.data
        })
      } else {
        console.log("res?.data", res?.data)
        dispatch({
          type: adset.getLiveStreamerAdset.failure,
          payload: res.data.msg,
        })        
        dispatch(showAlert('Error code: '+res?.data?.code, { type: 'error' }))
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: adset.getLiveStreamerAdset.failure,
        payload: error,
      })
      dispatch(showAlert(error, { type: 'error' }))
    })
}
