import * as liveVideoStreamStaticticsData from '../constant/statisticData';
import { showAlert } from './alert';
import api from '../utils/liveVideoStreamStaticticsDataAPI';
import qs from 'query-string';

export const getLiveVideoStreamStatisticsData = (payload) => (dispatch) => {
  dispatch({
    type: liveVideoStreamStaticticsData.getLiveVideoStreamStatisticsData
      .request,
    payload,
  });
  api
    .get(
      `streaming/live_videos_statistics?${qs.stringify({
        account_type: payload.type,
      })}`
    )
    .then((res) => {
      if (res?.data?.code === 200) {
        dispatch({
          type: liveVideoStreamStaticticsData.getLiveVideoStreamStatisticsData
            .success,
          payload: res?.data?.data,
        });
      } else {
        dispatch({
          type: liveVideoStreamStaticticsData.getLiveVideoStreamStatisticsData
            .failure,
          payload: res?.data?.msg,
        });
        dispatch(
          showAlert('Error code: ' + res?.data?.code, { type: 'error' })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: liveVideoStreamStaticticsData.getLiveVideoStreamStatisticsData
          .failure,
        payload: error,
      });
      dispatch(showAlert(error, { type: 'error' }));
    });
};
