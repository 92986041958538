import React from 'react';
import { useFormik, FormikProvider } from 'formik';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, withStyles } from '@material-ui/styles';
import CardActions from '@material-ui/core/CardActions';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { dspLogin } from 'actions/dsp';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10%',
  },
  card: {
    width: 500,
    height: 'auto',
  },
  item: {
    margin: '15px 10px',
  },
  formControl: {
    width: '100%',
  },
  focused: {
    // color: `${theme.palette.text.primary} !important`,
  },
  helper: {
    // color: theme.palette.error.main,
  },
  header: {
    padding: '5px 10px',
    marginTop: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    fontSize: 32,
    width: '100%',
  },
  logo: {
    width: 130,
    marginLeft: 20,
    height: 37,
    marginBottom: -9,
  },
  itemFlex: {
    display: 'flex',
    margin: '10px 0',
    '& button': {
      marginLeft: 10,
    },
  },
  actions: {
    justifyContent: 'center',
    padding: '14px 25px',
    // marginBottom: theme.spacing(1),
  },
  divide: {
    padding: '0 0 0 11px',
  },
  ucfunnelLogoWrapper: {
    textAlign: 'center',
    margin: '0 auto',
    '& img': {
      width: '180px',
    },
  },
}));

const LoginButton = withStyles((theme) => ({
  root: {
    width: '100%',
    height: '47px',
    color: '#F7968F',
    border: '2px solid #F7968F',
    borderRadius: '5px',
    backgroundColor: 'white',
    '&:hover': {
      color: '#CD0804',
      borderColor: '#CD0804',
      backgroundColor: 'white',
    },
  },
}))(Button);

const Login = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      passwd: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().max(50, 'Length Error').required('Account Required'),
      passwd: Yup.string()
        .max(50, 'Length Error')
        .required('Password Required'),
    }),
    onSubmit: (values) => {
      dispatch(dspLogin({ ...values })).then((res) => {
        if (res?.code === 200) navigate('/');
      });
    },
  });

  const { handleSubmit, values, handleChange, touched, errors } = formik;

  return (
    <Container maxWidth="sm" className={classes.root}>
      <FormikProvider value={formik}>
        <Card className={classes.card}>
          <CardContent>
            <h1 className={classes.header}>
              <span>Login</span>
            </h1>
            <div className={classes.item}>
              <InputLabel
                focused
                htmlFor="email"
                classes={{ focused: classes.focused }}
              >
                {'Email'}
              </InputLabel>
              <TextField
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                placeholder="XXXXX@XXXXX.com"
                fullWidth
              />
            </div>
            <div className={classes.item}>
              <InputLabel
                focused
                htmlFor="passwd"
                classes={{ focused: classes.focused }}
              >
                {'Password'}
              </InputLabel>
              <TextField
                id="password"
                name="passwd"
                type="password"
                fullWidth
                value={values.passwd}
                error={touched.passwd && Boolean(errors.passwd)}
                helperText={touched.passwd && errors.passwd}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !(touched.passwd && errors.passwd)) {
                    handleSubmit();
                  }
                }}
              />
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <LoginButton
              variant="outlined"
              type="button"
              onClick={handleSubmit}
            >
              {'Sign In'}
            </LoginButton>
          </CardActions>
        </Card>
      </FormikProvider>
    </Container>
  );
};

export default Login;
