import * as dsp from '../constant/dsp';
import { showAlert } from './alert';
import dspAPI from '../utils/dspAPI';

export const dspLogin = (payload) => (dispatch) => {
  dispatch({ type: dsp.dspLogin.request, payload });
  return dspAPI
    .post(`/ucfunnel_login`, payload)
    .then((res) => {
      if (res?.data?.code === 200) {
        if (res?.data?.token) {
          localStorage.setItem('liveStreamToken', res?.data?.token);
        }
        dispatch({
          type: dsp.dspLogin.success,
          payload: res.data.user,
        });
        return { code: 200 };
      } else {
        console.log('res?.data', res?.data);
        dispatch({
          type: dsp.dspLogin.failure,
          payload: res.data.msg,
        });
        dispatch(
          showAlert('Error code: ' + res?.data?.code, { type: 'error' })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        showAlert('Please check your email / password and try again', {
          type: 'error',
        })
      );
    });
};
