import React from 'react'
import { Alert } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import { useSelector, useDispatch } from 'react-redux'
import { hideAlert } from '../../actions/alert'

export default function CustomAlert() {
  const dispatch = useDispatch()
  const message = useSelector((state) => state.alert.text)
  const type = useSelector((state) => state.alert.type)
  const duration = useSelector((state) => state.alert.duration)
  const vertical = useSelector((state) => state.alert.vertical)
  const horizontal = useSelector((state) => state.alert.horizontal)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
      dispatch(hideAlert())
  }
  console.log("CustomAlert message", message)
  return (
    <Snackbar
      anchorOrigin={{
        vertical: vertical || 'bottom',
        horizontal: horizontal || 'right',
      }}
      open={Boolean(message)}
      autoHideDuration={duration || 3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type || 'success'}>
        {message}
      </Alert>
    </Snackbar>
  )
}

/*

Usage
- dispatch one of the following Action to control Alert Component.

example

dispatch(showAlert('demo_message'))
dispatch(showAlert('demo_message', {duration: 3000}))
dispatch(showAlert('demo_message', {vertical: 'top', horizontal: 'left', type: 'error'}))
dispatch(hideAlert())

Action:
  showAlert(text, {type='info', duration=3000, vertical='bottom', horizontal='right'})
    - arguments
      - type: The message type.
        value: 'error'|'warning'|'success'|'info'
        default: 'info'
      - duration: The number of milliseconds to wait before automatically close.
        default: 3000
      - vertical: The vertical position of the Snackbar.
        value: 'top'|'bottom'
        default: 'bottom'
      - horizontal: The horizontal position of the Snackbar.
        value: 'left'|'center'|'right'
        default: 'right'

  hideAlert()

*/
