import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

const API_ROOT = ` https://adops-server.aralego.com/api/v1/`;
const config = {
  baseURL: API_ROOT,
};
const agent = axios.create(config);

agent.interceptors.request.use(
  (config) => {
    if (config.data) config.data = snakecaseKeys(config.data, { deep: true });
    return config;
  },
  (error) => Promise.reject(error)
);

agent.interceptors.response.use(
  (response) => {
    if (process.env.REACT_APP_NEV === 'local') console.log(response);
    if (typeof response.data.data === 'object' && response.data.data) {
      response.data.data = camelcaseKeys(response.data.data, { deep: true });
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default agent;
