import { alertStatus } from '../constant/alert'

const alertState = {
  text: '',
};

export default function alert(state = alertState, action) {
  if (action.type.indexOf('failure') > -1) {
    return { ...state, type: 'error', text: action.payload }
  }
  switch (action.type) {
    case alertStatus.SHOW_ALERT:
      return { ...action.payload };
    case alertStatus.HIDE_ALERT:
      return { ...state, text: '' };
    default:
      return state;
  }
};
