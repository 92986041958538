import '../App.css';
import { useFormik, FormikProvider } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Creative from '../Creative';
import AdvertiserDetails from '../AdvertiserDetails';
import {
  Select,
  MenuItem,
  Button,
  IconButton,
  TextField,
  Grid,
  Collapse,
} from '@material-ui/core/';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveAdset } from '../actions/streamer';
import { getLiveVideoStreamStatisticsData } from 'actions/statisticData';
import { Line, Chart } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import moment from 'dayjs';

const usestyles = makeStyles((theme) => ({
  background: {
    margin: '20px',
  },
  button: {
    marginLeft: '20px',
  },
  select: {
    marginTop: '8px',
  },
  title: {},
  videoUrl: {
    fontSize: '0.75em',
  },
  greenlight: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    display: 'inline-block',
    float: 'left',
    backgroundColor: '#0f0',
  },
  redlight: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    display: 'inline-block',
    float: 'left',
    backgroundColor: '#f00',
  },
  yellowlight: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    display: 'inline-block',
    float: 'left',
    backgroundColor: '#ff0',
  },
  information: {
    marginLeft: '30px',
  },
}));

const divSize = [
  { width: 300, height: 250 },
  { width: 336, height: 280 },
  { width: 970, height: 250 },
];

const LivestreamInfo = () => {
  const dispatch = useDispatch();
  const [urlList, setUrlList] = useState([]);
  const [detailed, setDetailed] = useState(false);
  const [seed, setSeed] = useState(Date.now());
  const [detailInfo, setDetailInfo] = useState(-1); // focused index to liveAdsets
  const [userType, setUserType] = useState('');

  const statisticData =
    useSelector((state) => state?.statisticData?.statisticData) || [];
  const charts = useRef([]);

  const handleResetZoom = (ref) => {
    let chart = ref;
    chart.resetZoom();
  };
  const handleParseToken = () => {
    if (!localStorage.getItem('liveStreamToken')) return;
    const base64Payload = localStorage
      .getItem('liveStreamToken')
      ?.split('.')[1];
    const payload = Buffer?.from(base64Payload, 'base64');
    const tokenInfo = JSON.parse(payload.toString());
    setUserType(tokenInfo.user.type);
  };
  const liveAdsets = useSelector(
    (state) =>
      state?.adset?.liveAdset?.reduce((filtered, adset) => {
        if (adset.advertiser?.name !== 'Showmore') {
          let image,
            size = -1;
          for (let i = 0; i < 3; i++) {
            image = adset.stream?.images?.find((item) => {
              return (
                item.width === divSize[i].width &&
                item.height === divSize[i].height
              );
            });
            if (image) {
              size = i;
              break;
            }
          }
          let adsetStart = adset?.adsets?.reduce((min, adset) => {
            const start = new Date(adset.start);
            if (!min || min > start.getTime()) {
              min = start.getTime();
            }
            return min;
          }, '');
          let adsetEnd = adset?.adsets?.reduce((max, adset) => {
            const end = new Date(adset.end);
            if (!max || max < end.getTime()) {
              max = end.getTime();
            }
            return max;
          }, '');

          let adsetBudget = adset?.adsets?.reduce((budget, adset) => {
            return budget + parseFloat(adset.originTotalBudget);
          }, 0);

          let adsetUsage = adset?.adsets?.reduce((usage, adset) => {
            return usage + parseFloat(adset.totalBudgetUsage);
          }, 0);

          let totalImpressions = adset?.adsets?.reduce((impressions, adset) => {
            return impressions + parseInt(adset.totalImpressionUsage);
          }, 0);

          if (image) {
            filtered.push({
              url: adset.stream?.videoUrl,
              title: adset.advertiser?.name,
              adsetsInfoList: adset.adsets,
              imgUrl: image.url,
              backupImage: adset.stream?.backupImage,
              videoName: adset.stream?.name,
              size: size,
              landingPage: adset.stream.landingPage,
              viewers: adset.advertiser?.viewers,
              adsetDuration:
                Math.floor((adsetEnd - adsetStart) / 600 / 60) / 100, // in hours with 2 digits decimal
              adsetCurrent:
                Math.floor((Date.now() - adsetStart) / 600 / 60) / 100,
              adsetBudget: Math.floor(adsetBudget * 100) / 100,
              adsetUsage: Math.floor(adsetUsage * 100) / 100,
              totalImpressions: totalImpressions,
              detailViewEnabled: false,
            });
          } else {
            filtered.push({
              url: adset.stream?.videoUrl,
              title: adset.advertiser?.name,
              adsetsInfoList: adset.adsets,
              imgUrl: '',
              videoName: adset.stream?.name,
              backupImage: adset.stream?.backupImage,
              size: size < 0 ? 0 : size,
              landingPage: adset.stream.landingPage,
              viewers: adset.advertiser?.viewers,
              adsetDuration:
                Math.floor((adsetEnd - adsetStart) / 600 / 60) / 100, // in hours with 2 digits decimal
              adsetCurrent:
                Math.floor((Date.now() - adsetStart) / 600 / 60) / 100,
              adsetBudget: Math.floor(adsetBudget * 100) / 100,
              adsetUsage: Math.floor(adsetUsage * 100) / 100,
              totalImpressions: totalImpressions,
              detailViewEnabled: false,
            });
          }
        }
        return filtered;
      }, []) || []
  );
  const totalBudget = liveAdsets.reduce((filtered, adset) => {
    return filtered + adset.adsetBudget;
  }, 0);

  useEffect(() => {
    dispatch(getLiveAdset());
  }, [dispatch, seed]);

  useEffect(() => {
    handleParseToken();
  }, []);

  useEffect(() => {
    if (!userType) return;
    dispatch(
      getLiveVideoStreamStatisticsData({
        type: userType,
      })
    );
  }, [dispatch, seed, userType]);
  useEffect(() => {
    Chart.register(zoom);
  }, []);
  useEffect(() => {
    const timerId = setInterval(() => {
      if (Date.now() - seed > 30 * 60 * 1000) {
        setSeed(Date.now());
      }
      console.log('ticked');
    }, 60 * 1000);
    return () => clearInterval(timerId);
  }, [seed]); // auto refresh

  //  let seed=0;
  const classes = usestyles();
  const formik = useFormik({
    initialValues: {
      url: '',
      streamer: '',
      imgUrl: '',
      option: 1,
    },
    onSubmit: (values, { resetForm }) => {
      urlList.push({
        url: values.url,
        title: values.streamer,
        imgUrl: values.imgUrl,
        size: values.option,
        backup_image: '',
      });
      resetForm();
      setUrlList(urlList);
    },
  });
  const handleFieldChange = (fieldName, value) => {
    setFieldTouched(fieldName); // must before setFieldValue
    setFieldValue(fieldName, value.target.value);
  };
  const handleRefresh = () => {
    setUrlList(urlList.map((x) => x));
    setSeed(Date.now());
    // getLiveAdset()
  };
  const { handleSubmit, values, handleChange, setFieldValue, setFieldTouched } =
    formik;

  const handleGenerateXAxisLabel = (length) => {
    const temp = [];
    for (let i = 0; i <= length; i += 1) {
      temp.push(i);
    }
    return temp;
  };
  const handleAgencyChartOption = (url) => {
    const agencyData = statisticData?.find(
      (item) => item.videoUrl === url
    )?.averageVideoStartTime;
    return {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: '平均需要多久載入直播訊號',
        },
        zoom: {
          zoom: {
            pinch: {
              enabled: true,
            },
            mode: 'x',
            drag: {
              enabled: true,
              backgroundColor: '#77B6EA80',
              mode: 'y',
              borderColor: {
                color: '#77B6EA',
              },
            },
          },
        },
        tooltip: {
          callbacks: {
            title: (value) => `第${value[0]?.dataIndex + 1}分鐘`,
            label: (value) =>
              `${value.dataset.label}: ${value.formattedValue} 秒`,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            maxTicksLimit: agencyData?.length / 4,
            font: {
              size: 10,
            },
          },
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            color: '#EEA16D',
          },
          beginAtZero: true,
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    };
  };
  const handleAgencyData = (url) => {
    const agencyData = statisticData?.find(
      (item) => item?.videoUrl === url
    )?.averageVideoStartTime;
    const labels = handleGenerateXAxisLabel(agencyData?.length);
    return {
      labels,
      datasets: [
        {
          label: '平均需要多久載入直播訊號',
          data: agencyData,
          borderColor: '#EEA16D',
          backgroundColor: '#EEA16D',
          yAxisID: 'y1',
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    };
  };

  const handleDSPOwnerPage = (data, index) => {
    const options = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,

      plugins: {
        title: {
          display: true,
          text: '該場次各項數據',
        },
        zoom: {
          zoom: {
            pinch: {
              enabled: true,
            },
            mode: 'x',
            drag: {
              enabled: true,
              backgroundColor: '#77B6EA80',
              mode: 'y',
            },
          },
        },
        tooltip: {
          callbacks: {
            title: (value) => `第${value[0]?.dataIndex + 1}分鐘`,
            label: (value) => {
              let displayLabel = '';
              switch (value.dataset.label) {
                case '廣告曝光':
                  displayLabel = `${value.dataset.label}: ${value.formattedValue} 次`;
                  break;
                case '平均需要多久載入直播訊號':
                  displayLabel = `${value.dataset.label}: ${value.formattedValue} 秒`;
                  break;
                default:
                  displayLabel = `${value.dataset.label}: ${value.formattedValue} 人`;
              }
              return displayLabel;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            maxTicksLimit: data?.averageVideoStartTime?.length / 4,
          },
        },
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          beginAtZero: true,
          min: 0,
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          ticks: {
            color: '#EEA16D',
          },
          min: 0,
          beginAtZero: true,
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    };
    const DspOwnerData = {
      labels: handleGenerateXAxisLabel(data?.averageVideoStartTime?.length),
      datasets: [
        {
          label: '廣告曝光',
          data: data?.impressions || [],
          borderColor: '#5F7CB6',
          backgroundColor: '#5F7CB6',
          yAxisID: 'y',
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          label: '曝光UU數',
          data: data?.impressionsUu || [],
          borderColor: '#BC5C50',
          backgroundColor: '#BC5C50',
          yAxisID: 'y',
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          label: '曝光+廣告在*可視區域*內"UU數',
          data: data?.visibleImpressionsUu || [],
          borderColor: '#2F764E',
          backgroundColor: '#2F764E',
          yAxisID: 'y',
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          label: '同上人數',
          data: data?.averageFbViewers || [],
          borderColor: '#9462A1',
          backgroundColor: '#9462A1',
          yAxisID: 'y',
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          label: '平均需要多久載入直播訊號',
          data: data?.averageVideoStartTime || [],
          borderColor: '#EEA16D',
          backgroundColor: '#EEA16D',
          yAxisID: 'y1',
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    };
    return (
      <Grid xs={6} style={{ marginBottom: '8px' }}>
        <div>廣告主：{data?.advertiser}</div>
        <div>{data?.videoUrl}</div>
        <div>
          廣告開始時間：{moment(data?.start)?.format('YYYY-MM-DD HH:mm')}
        </div>
        <div>廣告結束時間：{moment(data?.end)?.format('YYYY-MM-DD HH:mm')}</div>
        <Line
          ref={(p) => {
            charts.current[index] = p;
          }}
          options={options}
          data={DspOwnerData}
        />
        <button onClick={() => handleResetZoom(charts.current[index])}>
          Reset Zoom
        </button>
      </Grid>
    );
  };
  return (
    <>
      {userType === 'agency' ? (
        <>
          <div id="fb-root"></div>
          <script
            async
            defer
            crossOrigin="anonymous"
            src="https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v12.0&appId=454705885070678&autoLogAppEvents=1"
            nonce="MPR6VeoT"
          ></script>
          <div className={classes.background}>
            {totalBudget > 30000 ? (
              <>
                <span className={classes.redlight}> </span>{' '}
                <p className={classes.information}>流量不足</p>
              </>
            ) : totalBudget > 22500 ? (
              <>
                <span className={classes.yellowlight}> </span>
                <p className={classes.information}>流量接近不足</p>
              </>
            ) : totalBudget > 0 ? (
              <>
                <span className={classes.greenlight}></span>
                <p className={classes.information}>流量充足 </p>{' '}
              </>
            ) : (
              <></>
            )}

            <FormikProvider value={formik}>
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="dense"
                  type="text"
                  name="url"
                  label="LiveStream url"
                  value={values.url}
                  onChange={handleChange}
                />
                <TextField
                  margin="dense"
                  type="text"
                  name="streamer"
                  label="直播主"
                  value={values.streamer}
                  onChange={handleChange}
                />
                <TextField
                  margin="dense"
                  type="text"
                  name="imgUrl"
                  label="圖片鏈結"
                  value={values.imgUrl}
                  onChange={handleChange}
                />
                <Select
                  className={classes.select}
                  autoWidth={true}
                  value={values.option}
                  defaultValue={0}
                  name="option"
                  onChange={(event) => {
                    handleFieldChange('option', event);
                  }}
                >
                  <MenuItem value={0}>970x250</MenuItem>
                  <MenuItem value={1}>300x250</MenuItem>
                  <MenuItem value={2}>336x280</MenuItem>
                </Select>
                <Button
                  className={classes.button}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  type="submit"
                >
                  新增監播
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleRefresh}
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                >
                  刷新
                </Button>
                <div
                  className="fb-login-button"
                  data-width=""
                  data-size="large"
                  data-button-type="continue_with"
                  data-layout="default"
                  data-auto-logout-link="false"
                  data-use-continue-as="false"
                ></div>
              </form>
            </FormikProvider>
            <Collapse in={detailInfo !== -1}>
              <p> {detailInfo !== -1 ? liveAdsets[detailInfo]?.title : ''}</p>
              <AdvertiserDetails
                data={liveAdsets?.[detailInfo]?.adsetsInfoList}
              />
            </Collapse>

            <Grid container spacing={3} justifyContent="space-between">
              {urlList?.map((item, idx) => (
                <Grid item xs={6} key={idx + seed}>
                  <div>
                    <p>{item.title} </p>
                    <p className={classes.videoUrl}>{item.url}</p>
                    <Creative
                      videoUrl={item.url}
                      size={item.size}
                      image={item.imgUrl}
                      videoName={item.videoName}
                    />
                    <Button
                      className={classes.button}
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setUrlList(urlList.filter((item, key) => key !== idx));
                      }}
                    />
                  </div>
                  <Line
                    ref={(p) => {
                      charts.current[idx] = p;
                    }}
                    options={handleAgencyChartOption(item.url)}
                    data={handleAgencyData(item.url)}
                  />
                  <button onClick={() => handleResetZoom(charts.current[idx])}>
                    Reset Zoom
                  </button>
                </Grid>
              ))}
              {liveAdsets?.map((item, idx) => {
                return (
                  <Grid item xs={6} key={idx + seed}>
                    <div>
                      <p>
                        {item.title} - 累積展示: {item.totalImpressions}{' '}
                      </p>
                      <p className={classes.videoUrl}>{item.url}</p>
                      <p>
                        {' '}
                        廣告時間: {item.adsetCurrent}/{item.adsetDuration} 小時
                      </p>
                      {window.debug === 1 && (
                        <p>
                          {' '}
                          預算: {item.adsetUsage}/{item.adsetBudget}
                        </p>
                      )}

                      <Creative
                        videoUrl={item.url}
                        size={item.size}
                        image={item.imgUrl}
                        landingPage={item.landingPage}
                        videoName={item.videoName}
                      />
                      {window.debug === 1 && (
                        <IconButton
                          className={classes.button}
                          onClick={(e) => {
                            if (detailInfo === idx) {
                              setDetailInfo(-1);
                            } else {
                              setDetailInfo(idx);
                            }
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                      )}
                    </div>
                    <div>
                      <Line
                        ref={(p) => {
                          charts.current[idx] = p;
                        }}
                        options={handleAgencyChartOption(item.url)}
                        data={handleAgencyData(item.url)}
                      />
                      <button
                        onClick={() => handleResetZoom(charts.current[idx])}
                      >
                        Reset Zoom
                      </button>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      ) : (
        <Grid container justifyContent="space-between">
          {statisticData?.map((data, index) => handleDSPOwnerPage(data, index))}
        </Grid>
      )}
    </>
  );
};

export default LivestreamInfo;
